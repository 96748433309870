<template>
  <div id="home_container">
    <HOMEHEADER ref="headerRef" :isPC="isPC" @goAnchor="goAnchor" />
    <div class="banner_box" id="home">
      <el-carousel arrow="never" :interval="6000" trigger="click">
        <el-carousel-item v-for="(p, index) in (isPC ? productList : mbProductList)" :key="index">
          <el-image :src="p" fit="contain">
            <div slot="placeholder" class="image-slot">
              加载中...
            </div>
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div id="services" class="plan_box">
      <div class="title_box">
        平台服务
      </div>
      <ul>
        <li v-for="(service, index) in serviceList" :key="index">
          <el-image :src="service.icon" fit="contain">
            <div slot="placeholder" class="image-slot">
              加载中...
            </div>
          </el-image>
          <div class="title">{{ service.title }}</div>
          <div>{{ service.desc }}</div>
        </li>
      </ul>
    </div>
    <div v-if="isPC" class="last_box">
      <div class="title">了解小紫</div>
      <el-image :src="require('@/assets/images/pcHome/xiaozi_info.png')" fit="contain" :lazy="true">
        <div slot="placeholder" class="image-slot">
          加载中...
        </div>
      </el-image>
    </div>
    <div v-if="!isPC" class="qrcode_box">
      <div class="title">了解小紫</div>
      <el-image :src="require('@/assets/images/mobileHome/xiaozi_info.png')" fit="contain" :lazy="true">
        <div slot="placeholder" class="image-slot">
          加载中...
        </div>
      </el-image>
    </div>
    <footerNav v-if="isPC" isHome />
    <div class="footer_box">
      <footerBox v-if="isPC" />
      <template v-else>
        <div class="code_title">扫码关注小紫平台应用</div>
        <div class="code_box_container">
          <div class="code_box">
            <el-image :src="require('@/assets/images/pcHome/xiaozi_pingtai.jpg')" fit="contain" :lazy="true">
              <div slot="placeholder" class="image-slot">
                加载中...
              </div>
            </el-image>
            <p>小紫平台</p>
          </div>
          <div class="code_box">
            <el-image :src="require('@/assets/images/pcHome/xiaozi_yigong.jpg')" fit="contain" :lazy="true">
              <div slot="placeholder" class="image-slot">
                加载中...
              </div>
            </el-image>
            <p>小紫医工</p>
          </div>
        </div>
        <div class="service">
          <span>1械网</span>
          <i class="el-icon-sort-down" @click="navTo('yixie')"></i>
        </div>
        <div class="service">
          <span>云应用</span>
          <i class="el-icon-sort-down" @click="navTo('cloud')"></i>
        </div>
        <div class='contact_wrapper'>
          <div class="contact">
            <span><i class="el-icon-phone-outline"></i>021-52813076</span>
            <span><i class="el-icon-message"></i>xzkefu@xiaoziyixue.cn</span>
          </div>
          <div class="contact"><span><i class="el-icon-location-outline"></i>上海市普陀区金沙江路1340弄20号A区3号楼3层</span></div>
        </div>
        <div class="record_wrapper">
          <div class="record">Copyright©2021 海南小紫医疗.</div>
          <div class="record">
            <a target="_blank" class="desc_sty" href="http://beian.miit.gov.cn/">琼ICP备2023003828号</a>
            <img class="desc_sty" :src="require('../assets/images/police.png')" />
            <a class="desc_sty">
              琼公网安备 46010502000684号
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import HOMEHEADER from './HomeComponents/homeHeader.vue';
import footerBox from './HomeComponents/footerBox.vue';
import footerNav from './HomeComponents/footerNav.vue';
import { getJsapiTicket } from '@/apis/wx.js';

const CryptoJS = require('crypto-js');

const gongxutong = require('@/assets/images/pcHome/xiaozi_gongxutong.png');
const yida = require('@/assets/images/pcHome/xiaozi_yida.png');
const huice = require('@/assets/images/pcHome/xiaozi_huice.png');
const yixie = require('@/assets/images/pcHome/xiaozi_yixie.png');
const yixiubao = require('@/assets/images/pcHome/xiaozi_yixiubao.png');
const quanlianlu = require('@/assets/images/pcHome/xiaozi_quanlianlu.png');

const mbGongxutong = require('@/assets/images/mobileHome/xiaozi_gongxutong.png');
const mbYida = require('@/assets/images/mobileHome/xiaozi_yida.png');
const mbHuice = require('@/assets/images/mobileHome/xiaozi_huice.png');
const mbYixie = require('@/assets/images/mobileHome/xiaozi_yixie.png');
const mbYixiubao = require('@/assets/images/mobileHome/xiaozi_yixiubao.png');
const mbQuanlianlu = require('@/assets/images/mobileHome/xiaozi_quanlianlu.png');

const EXPIRE_TIME = 7200; // jsapi_ticket有效期7200s
const NONCESSTR = 'xzpt';

export default {
  components: {
    HOMEHEADER,
    footerBox,
    footerNav,
  },
  watch: {
    currentPageW: {
      immediate: true,
      handler (val) {
        // 可视区域大于1200视为PC样式
        this.isPC = val > 800;
      },
    },
  },

  data () {
    return {
      currentPageW: document.documentElement.clientWidth || document.body.clientWidth,
      activeIndex: 0,
      showBackTop: false,
      activeName: 'first',
      isDrawerOpened: false,
      productList: [quanlianlu, gongxutong, yida, huice, yixie, yixiubao],
      mbProductList: [mbQuanlianlu, mbGongxutong, mbYida, mbHuice, mbYixie, mbYixiubao],
      carouselHeight: 800,
      serviceList: [{
        icon: require('@/assets/images/pcHome/xiaozi_caixiao.svg'),
        title: '采销服务',
        desc: '营销推广 精准对接',
      }, {
        icon: require('@/assets/images/pcHome/xiaozi_gongyinglian.svg'),
        title: '供应链协同',
        desc: '实时协同 高效透明',
      }, {
        icon: require('@/assets/images/pcHome/xiaozi_shengtai.svg'),
        title: '生态链接',
        desc: '术业专攻 开放共享',
      }, {
        icon: require('@/assets/images/pcHome/xiaozi_jishu.svg'),
        title: '技术支持与培训',
        desc: '知识开源 职能培训',
      }, {
        icon: require('@/assets/images/pcHome/xiaozi_jiaoliu.svg'),
        title: '交流共享',
        desc: '市场动态 行业趋势',
      }, {
        icon: require('@/assets/images/pcHome/xiaozi_fazhan.svg'),
        title: '高质量发展',
        desc: '创新驱动 产业升级',
      }, {
        icon: require('@/assets/images/pcHome/xiaozi_shuzi.svg'),
        title: '数字普惠',
        desc: 'aPaaS开源 低碳办公',
      }, {
        icon: require('@/assets/images/pcHome/xiaozi_shuzhi.svg'),
        title: '数智赋能',
        desc: 'dSaaS 产业大脑',
      }, {
        icon: require('@/assets/images/pcHome/xiaozi_xinxi.svg'),
        title: '信息安全',
        desc: '信息规范 机制完善',
      }],
      isPC: true,
    };
  },
  created () {
    this.setWxShareConfig();
    window.addEventListener('resize', this.resizeHandle, 100, true);
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$route.query?.id) {
        this.goAnchor(this.$route.query.id);
      }
    });
  },
  beforeDestroy () {
    localStorage.clear();
  },
  methods: {
    async setWxShareConfig () {
      const { body: jsapiTicket } = await getJsapiTicket();
      const timestamp = parseInt((new Date()).getTime() / 1000);
      const url = window.location.href.split('#')[0];

      const shaParam = `jsapi_ticket=${jsapiTicket}&noncestr=${NONCESSTR}&timestamp=${timestamp}&url=${url}`;

      const signature = CryptoJS.SHA1(shaParam).toString();

      window.wx.config({
        debug: false,
        appId: process.env.VUE_APP_WX_APPID,
        timestamp: timestamp,
        nonceStr: NONCESSTR,
        signature: signature,
        jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
      });

      window.wx.checkJsApi({
        jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
      });

      window.wx.ready(() => {
        window.wx.updateTimelineShareData({
          title: '小紫平台',
          link: window.location.href,
          imgUrl: url + 'ic_xiaozi.png',
        });
        window.wx.updateAppMessageShareData({
          title: '小紫平台',
          desc: '小紫平台致力于助力中小型服务商持续改进中国医学装备服务，一起为医疗器械维修的后市场保驾护航，携手共创，使医学装备维护管理变得更加 简单、高效、安全',
          link: window.location.href,
          imgUrl: url + 'ic_xiaozi.png',
        });
      });

      localStorage.setItem('timestamp', timestamp);

      requestAnimationFrame(this.checkJsapiTicketExpired);
    },
    checkJsapiTicketExpired () {
      const currentTimestamp = parseInt((new Date()).getTime() / 1000);
      const lastTimestamp = localStorage.getItem('timestamp');

      if (!lastTimestamp || currentTimestamp - lastTimestamp > EXPIRE_TIME) {
        this.setWxShareConfig();
      }
    },
    goAnchor (id) {
      this.$nextTick(() => {
        document.querySelector(id)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      });
    },
    // 跳转H5端
    goMobileHandle () {
      const url = process.env.VUE_APP_BASE_URL + 'xiaozi-xmb/wireless/open/miniapp/redirectUrlLink';
      window.location.href = url;
    },
    handleDrawerOpened (isOpened) {
      this.isDrawerOpened = isOpened;
    },
    resizeHandle () {
      this.currentPageW = document.documentElement.clientWidth || document.body.clientWidth;
    },
    navTo (flag) {
      let url = '';

      if (flag === 'yixie') {
        url = process.env.VUE_APP_MB_YIXIE_URL;
      } else if (flag === 'cloud') {
        url = process.env.VUE_APP_CLOUD_URL;
      }

      window.open(url, '_blank');
    },
  },
};
</script>
<style scoped src="./homePc.scss" lang="scss"></style>
