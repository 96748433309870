<template>
  <div class="footer_feature_container">
    <div class="footer_feature">
      <div class="nav_box left">
        <div class="nav_ul">
          <div class="nav_li">
            <div v-for="(item, index) in navListJson" :key="item.id"
              :class="['features']" @click="goFeatures(item, index)">{{
                item.tabName }}
            </div>
          </div>
          <div class="nav_li"><i class="el-icon-phone-outline"></i>服务热线：<span class="tel">021-52813076</span>（上午9：00-12：00
            下午13：00-17：30 周一至周五 节假日除外）</div>
          <div class="nav_li"><i class="el-icon-message"></i>邮箱：xzkefu@xiaoziyixue.cn</div>
          <div class="nav_li"><i class="el-icon-location-outline"></i>地址：上海市普陀区金沙江路1340弄20号A区3号楼3层</div>
        </div>
      </div>
      <div class="nav_box right">
        <div class="about_box">
          <div class="code_box">
            <img :src="require('@/assets/images/pcHome/xiaozi_pingtai.jpg')" />
            <p>小紫平台</p>
          </div>
          <div class="code_box">
            <img :src="require('@/assets/images/pcHome/xiaozi_yigong.jpg')" />
            <p>小紫医工</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FEATURES_DESC_JSON from './featuresDesc.js';
const navListJson = FEATURES_DESC_JSON.map(i => ({
  id: i.id,
  tabName: i.name,
  url: i.url,
}));
export default {
  props: {
    isHome: {
      type: Boolean,
      default: false,
    },
    sourcePage: {
      type: String,
    },
  },
  data () {
    return {
      navListJson,
    };
  },
  methods: {
    resetScrollTop () {
      if (document.documentElement.scrollTop) document.documentElement.scrollTop = 0;
      if (document.body.scrollTop) document.body.scrollTop = 0;
    },
    goAnchor (id) {
      this.$nextTick(() => {
        document.querySelector(id)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      });
    },
    goFeatures (item, index) {
      if (index === 0) {
        this.goAnchor(item.id);
      }
      if (index === 1 || index === 2) {
        window.open(item.url, '_blank');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.footer_feature_container {
  background: #333333;
  padding: 44px 364px 67px 358px;

  .footer_feature {
    color: #ffffff;
    display: flex;
    margin: auto;
    min-height: 200px;
    min-height: 200px;
    display: flex;

    .left {
      flex-shrink: 0;
    }

    .right {
      flex-grow: 1;
      padding-left: 30px;
    }

    .nav_box {
      .nav_li {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        line-height: 14px;

        &:first-child {
          padding-bottom: 16px;
        }

        &:not(:first-child) {
          padding: 16px 0;
        }

        .features {
          cursor: pointer;
          display: inline-block;
          color: rgb(255, 255, 255);

          &.disabled {
            cursor: not-allowed;
          }

          &:first-child {
            padding-right: 30px;
          }

          &:nth-child(2) {
            text-align: center;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
            padding: 0 30px;
          }

          &:last-child {
            text-align: right;
            padding-left: 30px;
          }
        }

        i {
          margin-right: 20px;
        }

        .tel {
          font-size: 24px;
        }
      }

      .about_box {
        display: flex;

        .code_box {
          flex-shrink: 0;
          margin-left: 20px;

          img {
            display: block;
            width: 120px;
            height: 120px;
            margin-bottom: 15px;
            border-radius: 6px;
          }

          p {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
