export default [
  {
    id: '#home',
    name: '首页',
  },
  {
    name: '1械网',
    url: process.env.VUE_APP_YIXIE_URL,
  },
  {
    name: '云应用',
    url: process.env.VUE_APP_CLOUD_URL,
  },
];
