<template>
  <div :class="['header_box', isFloat ? 'isFloat' : '']">
    <div class="menu_box">
      <div class="logo_box"></div>
      <div v-if="isPC" class="login_btn_group">
        <div class="nav_btn login_btn" @click="goMaintenance">登录</div>
        <div class="nav_btn register_btn" @click="goMaintenance('regis')">注册</div>
      </div>
      <nav v-if="isPC" class="menu_list isPc" id="menu_ul">
        <div v-for="(navItem, index) in NavDatas" :key="index" class="menu_item" @click="activeNavHandle(navItem, index)">
          {{ navItem.name }}
        </div>
      </nav>
      <div v-else class="mob_icon" @click="openDrawerHandle">
        <i :class="[drawerFlag ? 'el-icon-close' : 'el-icon-s-fold', 'el-icon']"></i>
      </div>
    </div>
    <el-drawer v-if="!isPC" class="drawerBox" title="导航" :visible.sync="drawerFlag" direction="rtl" size="235px"
      append-to-body :show-close="true">
      <div class="draw_body">
        <div class="draw_content">
          <template v-for="i in MbNavDatas">
          <a class="menu_item" :href="i.url" target="_blank" :key="i.url">{{ i.name }}</a>
          </template>
        </div>
        <div class="reg_btn" @click="navi">登录/注册</div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
const NavDatas = [
  {
    name: '首页',
    id: '#home',
  },
  {
    name: '1械网',
    url: process.env.VUE_APP_YIXIE_URL,
  },
  {
    name: '云应用',
    url: process.env.VUE_APP_CLOUD_URL,
  },
];

const MbNavDatas = [
  {
    name: '1械网',
    url: process.env.VUE_APP_MB_YIXIE_URL,
  },
  {
    name: '云应用',
    url: process.env.VUE_APP_CLOUD_URL,
  },
];

export default {
  props: {
    isPC: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      NavDatas,
      MbNavDatas,
      drawerFlag: false,
      isFloat: false,
    };
  },
  methods: {
    activeNavHandle (navData, index) {
      this.resetScrollTop();
      if (index === 0) {
        this.goAnchor(navData.id);
      }
      if (index === 1 || index === 2) {
        window.open(navData.url, '_blank');
      }
    },
    resetScrollTop () {
      if (document.documentElement.scrollTop) document.documentElement.scrollTop = 0;
      if (document.body.scrollTop) document.body.scrollTop = 0;
    },
    openDrawerHandle () {
      this.drawerFlag = !this.drawerFlag;
    },
    goAnchor (id) {
      this.$emit('goAnchor', id);
      this.drawerFlag = false;
    },
    goMaintenance (type) {
      const urlG = type === 'regis' ? `${process.env.VUE_APP_LOGIN_URL}?type=regis` : process.env.VUE_APP_LOGIN_URL;
      window.open(urlG, '_blank');
    },
    async navi () {
      window.open(`${process.env.VUE_APP_BASE_URL}xiaozi-xmb/wireless/open/miniapp/redirectUrlLinkForLogin`, '_blank');
    },
  },
  mounted () {
    window.onscroll = () => {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top > 0) {
        this.isFloat = true;
      } else {
        this.isFloat = false;
      }
    };
  },
};
</script>
<style lang="scss" scoped>
$mobileW: 800px;

@mixin headerfloat {
  background: #fff;

  .menu_box {
    .logo_box {
      background: url(../../assets/images/pcHome/xiaozi_hover.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .menu_list.isPc {
      .menu_item {
        color: #000000;
      }
    }

    .login_btn_group {
      .login_btn {
        color: #004190;
        border: 1px solid #004190;
      }

      .register_btn {
        background: #004190;
        color: #FFFFFF;
      }
    }
  }
}

@media (min-width: $mobileW) {
  .header_box {
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    background: rgba(255, 255, 255, 0);

    .menu_box {
      position: relative;
      line-height: 70px;
      height: 70px;
      padding: 0 60px;

      .logo_box {
        position: relative;
        float: left;
        width: 200px;
        height: 48px;
        padding: 11px 0;
        background: url(../../assets/images/pcHome/xiaozi.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .menu_list.isPc {
        position: relative;
        float: right;
        width: auto;

        .menu_item {
          position: relative;
          cursor: pointer;
          float: left;
          padding: 0 20px;
          margin-right: 60px;
          height: 70px;
          line-height: 70px;
          font-size: 16px;
          color: #fff;
          text-align: center;
        }
      }

      .login_btn_group {
        float: right;
        height: 70px;
        display: flex;
        align-items: center;
        margin-left: 20px;

        .nav_btn {
          display: inline-block;
          vertical-align: top;
          text-align: center;
          cursor: pointer;
          width: 80px;
          font-size: 16px;
        }

        .login_btn {
          color: #FFFFFF;
          border: 1px solid #FFFFFF;
          height: 34px;
          line-height: 34px;
          margin-right: 11px;
        }

        .register_btn {
          height: 36px;
          line-height: 36px;
          background: #FFFFFF;
          color: #004190;
        }
      }
    }

    &:hover {
      @include headerfloat;
    }

    &.isFloat {
      @include headerfloat;
    }
  }
}

@media (max-width: $mobileW) {
  .header_box {
    position: fixed;
    z-index: 100;
    width: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0);

    .menu_box {
      position: relative;
      height: 40px;

      .logo_box {
        position: relative;
        float: left;
        left: 12px;
        top: 6px;
        width: 106px;
        height: 28px;
        background: url(../../assets/images/pcHome/xiaozi.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .mob_icon {
        float: right;
        position: absolute;
        right: 16px;
        top: 8px;
        font-size: 24px;

        .el-icon {
          color: #fff;
        }
      }
    }

    &.isFloat {
      @include headerfloat;

      .mob_icon {
        .el-icon {
          color: #5F6A7A;
        }
      }
    }
  }

  .drawerBox {

    /deep/ .el-drawer__header {
      font-size: 12px;
      margin-bottom: 0;
      padding: 10px 20px;
      color: #333333;
    }

    .draw_body {
      position: relative;
      height: 100%;
      min-height: 350px;

      .draw_content {
        height: calc(100% - 60px);
        overflow-x: hidden;
        overflow-y: auto;
        padding: 4px 12px 0 12px;

        .menu_item {
          height: 44px;
          line-height: 44px;
          font-size: 12px;
          font-weight: 400;
          display: block;
          text-decoration-line: none;
          color: #242832;
          text-indent: 5px;
          padding-left: 10px;
          margin-bottom: 12px;
          box-shadow: inset 0px -1px 0px 0px #DFE2E8;

          &.active {
            color: #0f89ff;
          }

          .el-menu-item:hover {
            background: transparent;
          }

          &.el-menu {
            height: auto;
          }
        }
      }

      .reg_btn {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #004190;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #ffffff;
        font-size: 16px;
      }
    }
  }

  /deep/ .el-menu {
    border-right: none;

    .el-submenu__title {
      height: 46px;
      line-height: 46px;
      padding: 0;
      background: transparent;
      text-indent: -14px;
      font-size: 16px;
      font-weight: 400;
      color: #242832;

      &:hover {
        background: transparent;
      }
    }

    .el-menu-item {
      text-indent: -20px;
      font-size: 16px;
      color: rgba(36, 40, 50, 0.6);
    }
  }
}
</style>
